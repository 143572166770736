import React from 'react';
import Header from '../../components/common/Header/Header';

const ServicesManager = () => {
  return (
    <>
      <Header title="Services" slogan="Gestion de vos services"/>
    </>
  );
};

export default ServicesManager;