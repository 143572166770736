import React from 'react';
import Header from '../../components/common/Header/Header';

const AdminHome = () => {
  return (
    <>
        <Header title="Garage V.Parrot" slogan="Bienvenu sur votre espace administrateur"/>      
    </>
  );
};

export default AdminHome;