import React from 'react';
import Header from '../../components/common/Header/Header';

const UsersManager = () => {
  return (
    <div>
        <Header title="Utilisateurs" slogan="Gestion des utilisateurs"/>
    </div>
  );
};

export default UsersManager;