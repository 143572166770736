import React from 'react';
import Header from '../../components/common/Header/Header';

const CarsManager = () => {
  return (
    <>
      <Header title="Parc Auto" slogan="Gestion des ventes de véhicules"/>
      
    </>
  );
};

export default CarsManager;