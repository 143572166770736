import React from 'react';

const Error = () => {
  return (
    <div>
        Error 404 !     
    </div>
  );
};

export default Error;